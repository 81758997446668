import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import SecondaryNavigation from "./secondary-navigation"
import { mq, site } from "../styles/variables"

const Wrapper = styled.footer`
  padding: 50px 0 60px;
  position: relative;

  &::before {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: black;
    transform: scale(0, 1);
    transform-origin: 0 50%;
    transition: transform 700ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .no-js &,
  &.is-active::before {
    transform: scale(1, 1);
  }

  @media ${mq.gte("m")} {
    padding: 62px 0 40px;
  }
`

const Columns = styled.div`
  @media (min-width: 1080px) {
    display: flex;
    justify-content: flex-start;
  }

  @media ${mq.gte("l")} {
    margin-bottom: 50px;
  }
`

const Heading = styled.h3`
  margin-bottom: 0.5em;
`

const Locations = styled.div`
  display: grid;
  grid-column-gap: ${site.gridGutter}px;

  grid-template-columns: repeat(
    ${(props) => Math.ceil(props.$length / 2)},
    1fr
  );

  @media ${mq.gte("m")} {
    grid-template-columns: repeat(${(props) => props.$length}, 1fr);
  }

  @media ${mq.gte("xl")} {
    width: ${(props) => (50 / 3) * props.$length}%;
  }
`

const Location = styled.div`
  font-size: 16px;
  white-space: nowrap;

  @media ${mq.gte("m")} {
    &:not(:first-child) {
      //margin-left: 60px;
    }
  }
`

const SiteFooter = () => {
  let {
    sanityOfficeLocations: { officeLocations },
  } = useStaticQuery(graphql`
    query SiteFooterQuery {
      sanityOfficeLocations {
        officeLocations {
          _key
          country
          text
        }
      }
    }
  `)

  const wrapper = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            wrapper.current.classList.add("is-active")
            observer.unobserve(entry.target)
          }
        }
      },
      { rootMargin: "0px 0px -60px 0px" }
    )

    observer.observe(wrapper.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <Wrapper ref={wrapper}>
      <Columns>
        <Locations $length={officeLocations.length}>
          {officeLocations.map(({ _key, country, text }) => (
            <Location key={_key} className="reveal">
              <Heading>{country}</Heading>
              <p
                dangerouslySetInnerHTML={{
                  __html: text.replace(/\n/g, "<br>"),
                }}
              />
            </Location>
          ))}
        </Locations>

        <SecondaryNavigation />
      </Columns>
    </Wrapper>
  )
}

export default SiteFooter
